<template>
  <div>
    <div>
      <b-row>
        <b-col>
          <component
            :is="primaryContactInformation[field].type"
            v-for="field in ['first_name', 'middle_name', 'last_name']"
            :key="field"
            v-model="primaryContactInformationForm[field]"
            v-bind="getProps(field)"
            md="6"
          />
          <!--          @blur="checkCustName"-->
          <component
            :is="primaryContactInformation['birthday'].type"
            v-model="primaryContactInformationForm['birthday']"
            md="6"
            :options="{date: true, datePattern: primaryContactInformationForm['datePattern'], delimiter: '/'}"
            :input-placeholder="primaryContactInformationForm['birthdayInputPlaceholder']"
            v-bind="getProps('birthday')"
          />
          <component
            :is="primaryContactInformation['job_title'].type"
            v-model="primaryContactInformationForm['job_title']"
            v-bind="getProps('job_title')"
            md="6"
          />
          <b-form-group>
            <label class="contacts-label font-weight-bolder">{{ $t('Contact Type') }}</label>
            <b-form-checkbox
              v-for="option in contactTypes"
              :id="option.value"
              :key="option.value"
              v-model="primaryContactInformationForm[option.value]"
              class="contacts-checkbox-groups contacts-checkbox"
            >
              <span
                class="pl-1 ml-2"
              >
                {{ option.text }}
              </span>
            </b-form-checkbox>
          </b-form-group>
          <div>
            <label class="font-weight-bolder pl-1">{{ $t('Preferred contact method') }}</label>
            <b-form-group
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                v-for="radio in preferredContactMethod"
                :key="radio.value"
                v-model="primaryContactInformationForm['preferred_contact_method']"
                class="custom-radio-group-input"
                :aria-describedby="ariaDescribedby"
                :name="radio.value"
                :value="radio.value"
              >
                {{ $t(radio.title) }}
              </b-form-radio>
            </b-form-group>
          </div>
          <!--          @input="setCustNameDb"-->
          <!--                    <component-->
          <!--                      :is="primaryContactInformation['cust_name_dba'].type"-->
          <!--                      ref="refNameDba"-->
          <!--                      v-model="primaryContactInformationForm.cust_name_dba"-->
          <!--                      v-bind="getProps('cust_name_dba')"-->
          <!--                      :check-unique="isDBNNameValid"-->
          <!--                      @backResponse="checkCustomerName"-->
          <!--                    />-->
        </b-col>
        <b-col>
          <component
            :is="primaryContactInformation[field].type"
            v-for="field in ['email']"
            :key="field"
            v-model="primaryContactInformationForm[field]"
            md="6"
            v-bind="getProps(field)"
          />
          <div
            v-for="field in ['phone_one']"
            :key="field"
            class="d-flex align-items-start"
          >
            <component
              :is="primaryContactInformation[field].type"
              v-model="primaryContactInformationForm[field]"
              md="6"
              style="width: 55%;"
              v-bind="getProps(field)"
              @input="handlePhoneInput(field)"
              @input-obj="setObjectOfPhoneMusk($event, primaryContactInformationForm,`${field}_object`)"
            />
            <div style="width: 20%; margin-top: 28px;:">
              <!--              <label class="pt-0">Ext</label>-->

              <b-form-input
                v-model="primaryContactInformationForm[`${field}_ext_val`]"
                style="height: 40px"
                placeholder="ext"
              />
            </div>
            <div style="width: 25%; margin-top: 28px;: ">
              <v-select
                id="countryList"
                v-model="primaryContactInformationForm[`${field}_type`]"
                class="v-select--custom"
                label="label"
                :options="phoneOptions"
                :placeholder="getPlaceholder(field)"
              />
            </div>
          </div>
          <div
            style="transform: translateY(-20px)"
          >
            <div
              class="text-left inputLabelNormal"
              style="transform: translateY(18px)"
            >
              {{ $t('Additional Phone Numbers') }}
            </div>
            <div
              v-for="field in ['phone_two', 'phone_three', 'phone_four']"
              :key="field"
              class="d-flex align-items-start"
            >
              <component
                :is="primaryContactInformation[field].type"
                v-model="primaryContactInformationForm[field]"
                md="6"
                style="width: 55%;transform: translateY(28px)"
                v-bind="getProps(field)"
                @input="handlePhoneInput(field)"
                @input-obj="setObjectOfPhoneMusk($event, primaryContactInformationForm,`${field}_object`)"
              />
              <div style="width: 20%; margin-top: 28px;:">
                <!--              <label class="pt-0">Ext</label>-->

                <b-form-input
                  v-model="primaryContactInformationForm[`${field}_ext_val`]"
                  style="height: 40px"
                  placeholder="ext"
                />
              </div>
              <div style="width: 25%; margin-top: 28px;: ">
                <v-select
                  id="countryList"
                  v-model="primaryContactInformationForm[`${field}_type`]"
                  class="v-select--custom"
                  label="label"
                  :options="phoneOptions"
                  :placeholder="getPlaceholder(field)"
                />
              </div>
            </div>
          </div>

          <div>
            <label class="contacts-label">{{ $t('Attachments') }}</label>
            <div class="d-flex justify-content-end">
              <feather-icon
                style="min-width: 37px; min-height: 37px;"
                icon="LAddButtonIconWhite"
                size="24"
                class="cursor-pointer feather-add-icon"
                @click="showByRef"
              />
            </div>
            <table
              class="table b-table table-striped"
            >
              <thead>
                <tr>
                  <!--it checks if any file uploaded, not being accurate when empty-->
                  <th
                    v-if="files.length"
                    style="width: 150px;"
                    class="pr-0 pl-1"
                  />
                  <th
                    :style="files.length ? 'width: 145px;' : ''"
                    class="pl-0 py-1 pr-1"
                  >
                    {{ $t('File Name') }}
                  </th>
                  <th
                    style="width: 200px;"
                    class="pl-0 py-1 pr-1"
                  >
                    {{ $t('Date') }}
                  </th>
                  <th
                    class="pl-0 py-1 pr-1"
                  >
                    {{ $t('Type') }}
                  </th>
                  <th
                    style="width: 125px;"
                    class="pl-0 py-1 pr-1"
                  >
                    {{ $t('Category') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(file, index) in files"
                  :key="index"
                  class="pb-2"
                >
                  <td
                    class="pr-0 pl-0"
                  >
                    <span class="upload-file__files-list-item-del">
                      <feather-icon
                        icon="LTrashIcon"
                        size="28"
                        style="padding: 5px; margin-left: 5px !important;"
                        class="cursor-pointer mr-1 ml-1 border-dotted feather-trash-icon"
                        @click="uploadedFiles.splice(index, 1)"
                      />
                    </span>
                    <span>
                      <a @click="showUploadedAttachment(file.path)">
                        <feather-icon
                          icon="LEyeIcon"
                          size="28"
                          style="padding: 5px;"
                          class="cursor-pointer border-dotted feather-eye-icon greyIcon"
                        />
                      </a>
                    </span>
                  </td>
                  <td
                    class="pr-1 pl-0"
                  >
                    {{ truncateFileName(file.name) }}
                  </td>
                  <td class="pr-1 pl-0">
                    {{ currentDate }}
                  </td>
                  <td class="pr-1 pl-0">
                    {{ getDocumentTypeByString(file.type) }}
                  </td>
                  <td class="pr-1 pl-0">
                    {{ getListOfCategories(file.category) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="!files.length"
              class="text-center upload-file__files-list-no-data border mb-md-1"
            >
              {{ $t('None Found') }}
            </div>
            <div
              v-else
              class="upload-file__files-list"
            >
              <div
                v-if="isLoading"
                class="d-flex justify-content-center my-2"
              >
                <b-spinner />
              </div>
              <div v-if="!isLoading" />

            </div>

            <l-file-upload
              ref="refContactsFileUpload"
              v-model="chosenFiles"
              :is-editable="ACCESS_ABILITY_FOR_ACCOUNTS"
              :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
              @input="showModalToChoseCategory"
            />
            <b-modal
              id="attachment-create-modal"
              centered
              hide-header
              hide-footer
              body-class="l-modal__body"
              footer-class="l-modal-footer"
              ok-title="Upload"
              ok-variant="success"
              :ok-disabled="isOkUploadBtnActive"
              :cancel-disabled="isCancelUploadBtnActive"
              cancel-variant="outline-primary"
              @hidden="clearData"
              @ok="uploadFile"
            >
              <b-overlay
                :show="isUploading"
                style="margin-bottom: -10px"
              >
                <div class="l-modal__container p-2">
                  <b-row>
                    <b-col cols="12">
                      <h3
                        class="l-modal__title"
                        style="font-size: 24px"
                      > {{ $t('How should this file be categorized?') }}
                      </h3>
                    </b-col>
                    <b-col>
                      <p class="text-center">
                        {{ $t('Please mind that you can only upload files up to 4MB each.') }}
                      </p>
                      <label class="ml-1">{{ $t('Category') }}</label>
                      <l-select
                        v-model="sendData.attachment_category_id"
                        name="attachment_category_id"
                        :field="{
                          options: { transition: '' },
                          store: 'attachmentCategoryListContact',
                        }"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-overlay>
              <b-row class="text-center">
                <b-col
                  cols="6"
                  class="text-left"
                >
                  <b-button
                    variant="outline-primary"
                    :disabled="isCancelUploadBtnActive"
                    class="font-medium-1 font-weight-bolder text-dark align-center mb-2 pl-3 pr-3 ml-2"
                    @click="hideModalToChoseCategory"
                  >
                    <feather-icon
                      icon="LCancelIcon"
                      size="16"
                      class="align-center"
                    />
                    {{ $t('Cancel') }}
                  </b-button>
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b-button
                    variant="success"
                    class="font-medium-1 font-weight-bolder align-center mb-2 pl-3 pr-3 mr-2"
                    :disabled="isOkUploadBtnActive"
                    @click="uploadFile"
                  >{{ $t('Upload') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-modal>
          </div>
        </b-col>
      </b-row>

    </div>
    <!--    <l-modal-unique-->
    <!--      id="add-customer-name-unique-modal"-->
    <!--      ref="uniqueModal"-->
    <!--      :exist-customers="existCustomer"-->
    <!--      @ok="stayInThisPage"-->
    <!--      @cancel="redicrectToTheExistingCustomerUpdate"-->
    <!--      @hide="clearExistingCustomer"-->
    <!--    />-->
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BModal,
  BOverlay,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import LFileUploadAttachments from '@/views/main/customers/components/LFileUploadAttachments.vue'
import LModalUnique from '@/views/components/LModalUnique.vue'
import lFileUpload from '@/views/main/customers/view/attachments/LFileUpload.vue'
import { getCurrentFormattedDate } from '@core/utils/utils'
import vSelect from 'vue-select'
import config from '../../customConfig'

export default {
  name: 'AddPrimaryContact',
  components: {
    BSpinner,
    lFileUpload,
    LFileUploadAttachments,
    ErrorAlert,
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    BOverlay,
    BFormRadio,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    LModalUnique,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      error: {},
      required,
      // existCustomer: [],
      currentNameDba: null,
      id: this.$route.params.id,
      isLoading: false,
      isUploading: false,
      uploadedFiles: [],
      attachments: [],
      index: 0,
      chosenFiles: null,
      phoneFields: ['phone_one', 'phone_two', 'phone_three', 'phone_four'],
      currentDate: getCurrentFormattedDate().slice(0, -10),
      sendData: {
        attachment_category_id: null,
        model_type: 0,
        file: null,
      },
    }
  },
  computed: {
    attachmentCategoryListContact() {
      return this.$store.state.listModule.attachmentCategoryListContact
    },
    contactTypes() {
      return this.$store.state.listModule.contactTypes
    },
    primaryContactInformationForm() {
      return this.$store.state[this.MODULE_NAME].primaryContactInformationForm
    },
    defCustomer() {
      return this.$store.state[this.MODULE_NAME].customer
    },
    paymentOptions() {
      return this.$store.state.listModule.paymentOptionList
    },
    customerRules() {
      return this.$store.state.listModule.customerRulesList
    },
    isOkUploadBtnActive() {
      return this.isUploading || !this.sendData.attachment_category_id
    },
    isCancelUploadBtnActive() {
      return this.isUploading
    },
    isOkTrashBtnActive() {
      return this.isUploading
    },
    isCancelTrashBtnActive() {
      return this.isUploading
    },
    files() {
      const mapUploadedFiles = this.uploadedFiles.map(file => ({
        category: file.attachment_category_id,
        type: file.file.type,
        name: file.file.name,
        path: (window.URL || window.webkitURL).createObjectURL(file.file),
      }))

      return mapUploadedFiles
    },
  },
  created() {
    this.currentNameDba = this.defCustomer.cust_name_dba
  },
  mounted() {
    this.unSelectEmpty()
  },
  methods: {
    unSelectEmpty() {
      this.phoneFields.forEach(field => {
        this.primaryContactInformationForm[`${field}_type`] = null
      })
    },
    handlePhoneInput(field) {
      const fieldValue = this.primaryContactInformationForm[field]
      if (!fieldValue) {
        this.primaryContactInformationForm[`${field}_type`] = null
      } else {
        this.primaryContactInformationForm[`${field}_type`] = this.phoneOptions.find(option => option.indexName === field) // Set to the default option
      }
    },
    getPlaceholder(field) {
      return this.phoneOptions.find(option => option.indexName === field).label
    },
    showByRef() {
      this.$refs.refContactsFileUpload.clickFileInput()
    },
    // HI PLS DON'T DELETE THIS FUNCTION ======================= ==>
    // setCustNameDb() {
    //   const firstName = this.primaryContactInformationForm.first_name
    //   const lastName = this.primaryContactInformationForm.last_name
    //   this.primaryContactInformationForm.cust_name_dba = `${firstName ?? ''} ${lastName ?? ''}`
    // },
    // HI PLS DON'T DELETE THIS FUNCTION ======================= ==>
    // checkCustName() {
    //   const firstName = this.primaryContactInformationForm.first_name
    //   const lastName = this.primaryContactInformationForm.last_name
    //   const searchQuery = `${firstName} ${lastName}`
    //   if (firstName && lastName) {
    //     this.$refs.refNameDba.onValidationByParams(searchQuery, this)
    //   }
    // },
    // isDBNNameValid(response) {
    //   // if you return false it will be valid if you return true it will display error
    //   return response.cust_name_dba === this.defCustomer.cust_name_dba
    // },
    getProps(fieldName) {
      return {
        is: this.primaryContactInformation[fieldName].type,
        field: this.primaryContactInformation[fieldName],
        name: fieldName,
      }
    },
    // stayInThisPage() {
    //   this.$refs.uniqueModal.hide()
    // },
    // checkCustomerName(customer) {
    //   if (!this.defCustomer.id && customer.length !== 0 && typeof customer === 'object') {
    //     this.existCustomer = customer
    //     this.$refs.uniqueModal.show()
    //   }
    // },
    // redicrectToTheExistingCustomerUpdate(id) {
    //   this.$router.push({ name: 'home-customers-general-info', params: { id } })
    // },
    // clearExistingCustomer() {
    //   this.existCustomers = []
    // },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-create-modal')
    },
    uploadFile() {
      const file = this.chosenFiles[0]
      const newUploadedDocID = Number(this.sendData.attachment_category_id.id)
      const mappedData = this.attachments.map(item => Number(item.attachment_category_id))
      const exists = mappedData.includes(newUploadedDocID)
      if (exists) {
        this.isUploading = false
        this.hideModalToChoseCategory()
        this.$bvToast.toast(this.$t('There is an attachment already with the same category'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      if (file.size > 4 * 1024 * 1024) {
        // 4MB limit
        this.isUploading = false
        this.hideModalToChoseCategory()
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      this.isUploading = true
      this.uploadedFiles.push(this.mapDataObject())
      this.primaryContactInformationForm.attachments = this.uploadedFiles
      this.postData(this.mapDataObject())
    },
    postData(formData) {
      this.attachments.push(formData)
      this.isUploading = false
      this.hideModalToChoseCategory()
    },
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-create-modal')
    },
    clearData() {
      this.sendData.attachment_category_id = null
      this.sendData.file = null
      this.chosenFiles = null
      this.$refs.refContactsFileUpload.clearInputValue()
    },
    showUploadedAttachment(path) {
      window.open(path, '_blank')
    },
    mapDataObject() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
        file: this.chosenFiles[0],
      }
    },
    truncateFileName(fileName) {
      // Extract file extension
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)

      // Truncate file name, leaving space for extension and ellipsis
      const maxLength = 10 // Adjust as needed
      const truncatedName = fileName.length > maxLength ? `${fileName.substring(0, maxLength - 3)}.` : fileName

      return `${truncatedName}.${fileExtension}`
    },
    getDocumentTypeByString(document) {
      let docType = 'Document'
      if (document.includes('image')) {
        docType = 'Image'
      }
      return docType
    },
    getListOfCategories(value) {
      console.log('CATTT:', value)
      const list = this.attachmentCategoryListContact
      let theLabelOfFile = ''
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= list.length; i++) {
        if (list[i]?.id === value) {
          theLabelOfFile = list[i]?.name
        }
      }
      return theLabelOfFile
    },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const {
      primaryContactInformation, setObjectOfPhoneMusk, preferredContactMethod, ACCESS_ABILITY_FOR_ACCOUNTS,
      phoneOptions,
    } = config()
    return {
      ACCESS_ABILITY_FOR_ACCOUNTS,
      MODULE_NAME,
      phoneOptions,
      preferredContactMethod,
      setObjectOfPhoneMusk,
      primaryContactInformation,
    }
  },
}
</script>
