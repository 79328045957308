<template>
  <div>
    <div>
      <b-row>
        <b-col>
          <!--        @input="setCustNameDb"-->
          <component
            :is="accountParameters['cust_name_dba'].type"
            ref="refNameDba"
            v-model="accountParametersForm.cust_name_dba"
            v-bind="getProps('cust_name_dba')"
            :check-unique="isDBNNameValid"
            @backResponse="checkCustomerName"
          />
          <component
            :is="accountParameters[field].type"
            v-for="field in ['is_active']"
            :key="field"
            v-model="accountParametersForm[field]"
            md="6"
            :default-value="settingCustomerDefault.status"
            v-bind="getProps(field)"
          />
          <component
            :is="accountParameters[field].type"
            v-for="field in ['currency_id', 'location_id']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="accountParametersForm[field]"
            md="6"
            v-bind="getProps(field)"
            class="h-auto"
          />
          <component
            :is="accountParameters[field].type"
            v-for="field in ['payment_term_id', 'payment_method_id']"
            :ref="`${field}_ref`"
            :key="field"
            v-model="accountParametersForm[field]"
            :v-if="hasKey(field)"
            v-bind="getProps(field)"
            :taggable="false"
          />
          <component
            :is="accountParameters[field].type"
            v-for="field in ['price_tier_id', 'comments']"
            :key="field"
            v-model="accountParametersForm[field]"
            md="6"
            v-bind="getProps(field)"
            class="h-auto"
          />
        <!--        <div>-->
        <!--          <label class="font-weight-bolder">{{ $t('Payment options') }}</label>-->
        <!--          <div-->
        <!--            v-for="({ name, id}) in paymentOptions"-->
        <!--            :key="id + 'paymentOption'"-->
        <!--            class="d-flex align-content-center justify-content-between"-->
        <!--          >-->
        <!--            <label-->
        <!--              :for="id.toString()"-->
        <!--              class="inputLabelNormal d-flex align-items-center"-->
        <!--            >{{ name }}</label>-->
        <!--            <b-form-checkbox-->
        <!--              :id="id.toString()"-->
        <!--              type="checkbox"-->
        <!--              class="d-flex"-->
        <!--              @change="toggleIds(id, 'payment_options_ids')"-->
        <!--            />-->
        <!--          </div>-->
        <!--&lt;!&ndash;          <div&ndash;&gt;-->
        <!--&lt;!&ndash;            v-if="isChecked(getNeedObj(paymentOptions, '3rd party payment option').id, accountParametersForm.payment_options_ids)"&ndash;&gt;-->
        <!--&lt;!&ndash;          >&ndash;&gt;-->
        <!--&lt;!&ndash;            <select-account&ndash;&gt;-->
        <!--&lt;!&ndash;              class="pt-1"&ndash;&gt;-->
        <!--&lt;!&ndash;              :auto-suggest-table-columns="suggestionTableColumns"&ndash;&gt;-->
        <!--&lt;!&ndash;              :module-name="MODULE_NAME"&ndash;&gt;-->
        <!--&lt;!&ndash;              @selectHandler="selectHandler"&ndash;&gt;-->
        <!--&lt;!&ndash;            />&ndash;&gt;-->
        <!--&lt;!&ndash;          </div>&ndash;&gt;-->
        <!--        </div>-->
        </b-col>
        <b-col>
          <div>
            <label class="inputLabel">{{ $t('Business Type') }}</label>
            <component
              :is="accountParameters[field].type"
              v-for="field in ['allow_to_rent_from_subrent', 'allow_to_purchase_from_supplier']"
              :key="field"
              v-bind="getProps(field)"
            />
            <div
              v-for="{id, name} in customerRules"
              :key="id + 'customerRules'"
              class="d-flex align-content-center"
            >
              <b-form-checkbox
                :id="id.toString() + name"
                type="checkbox"
                :checked="customerRulesChecked(id)"
                class="d-flex align-items-center"
                @change="toggleIds(id, 'customer_rules_ids')"
              />
              <label
                :for="id.toString() + name"
                class="inputLabelNormal d-flex align-items-center"
              >{{ name }}</label>
            </div>
          </div>
          <component
            :is="accountParameters[field].type"
            v-for="field in ['pseudonym','account_manager_id','tax_rule_id', 'delivery_type_id', 'ein_tax_number', 'duns_number', 'discount', 'credit_limit']"
            :key="field"
            v-model="accountParametersForm[field]"
            md="6"
            v-bind="getProps(field)"
          />
          <component
            :is="accountParameters['tags'].type"
            v-if="hasKey('tags')"
            :ref="`${'tags'}_ref`"
            v-model="accountParametersForm.tags"
            v-bind="getProps('tags')"
          />
        </b-col>
      </b-row>
    </div>

    <l-modal-unique
      id="add-customer-name-unique-modal"
      ref="uniqueModal"
      :exist-customers="existCustomer"
      @ok="stayInThisPage"
      @cancel="redicrectToTheExistingCustomerUpdate"
      @hide="clearExistingCustomer"
    />
  </div>
</template>

<script>
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import {
  BButton, BCol, BForm, BRow, BFormRadio, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import customerDefault from '@/store/settings/customer/customer-defaults'
import store from '@/store'
import vSelect from 'vue-select'
import LModalUnique from '@/views/components/LModalUnique.vue'
import config from '../../customConfig'
import SelectAccount from './SelectAccount.vue'

export default {
  name: 'BusinessInformation',
  components: {
    LModalUnique,
    vSelect,
    SelectAccount,
    ErrorAlert,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormRadio,
    BModal,
    BFormCheckbox,
  },
  data() {
    return {
      optionSelect: 1,
      existCustomer: [],
    }
  },
  computed: {
    settingCustomerDefault() {
      return this.$store.state[this.CUSTOMER_DEFAULT].customer
    },
    settingsCustomer() {
      return this.$store.state[this.MODULE_NAME].customerForm
    },
    accountParametersForm() {
      return this.$store.state[this.MODULE_NAME].accountParametersForm
    },
    customer() {
      return this.$store.state[this.MODULE_NAME].accountParametersForm
    },
    getCustomer() {
      return this.$store.state[this.MODULE_NAME].accountParametersForm
    },
    paymentOptions() {
      return this.$store.state.listModule.paymentOptionList
    },
    customerRules() {
      return this.$store.state.listModule.customerRulesList
    },
    defCustomer() {
      return this.$store.state[this.MODULE_NAME].customer
    },
    paymentTermsList() {
      return this.$store.state.listModule.paymentTermList
    },
  },
  created() {
    this.$store.dispatch('listModule/paymentTermList', { is_active: 1 })
    this.$store.dispatch('listModule/customerRulesList')
  },
  methods: {
    defaultSelectedField(field) {
      return field.filter(key => key in this.accountParameters).map(key => ({ key, value: this.accountParameters[key] }))
    },
    // gedDefaultSelectedField(key) {
    //   return this.defaultSelectedField(['currency_id', 'location_id', 'payment_term_id', 'price_tier_id']).filter(item => item.key === key)
    // },
    customerRulesChecked(ruleId) {
      return this.settingCustomerDefault.customer_rules.some(rule => rule.id === ruleId)
    },
    // selectHandler(item) {
    //   const {
    //     id, customer_detail, cust_name_dba, default_address,
    //   } = item
    //   // this.customer.customer_id_3rd_party_payment_option = {
    //   //   id, customer_detail, cust_name_dba, default_address,
    //   // }
    // },
    // getNeedObj(arr, name) {
    //   return arr.find(item => item.name === name)
    // },
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.accountParameters.hasOwnProperty(key)
    },
    isChecked(id, listArray) {
      return Array.isArray(listArray) && listArray.includes(String(id))
    },
    toggleIds(id, field) {
      if (this.accountParametersForm[field].includes(String(id))) {
        this.accountParametersForm[field] = this.accountParametersForm[field].filter(pId => pId !== String(id))
      } else {
        this.accountParametersForm[field].push(String(id))
      }
    },
    getProps(fieldName) {
      // if (fieldName === 'customer_id_3rd_party_payment_option') {
      //   const props = {
      //     is: this.accountParameters[fieldName].type,
      //     field: { ...this.accountParameters[fieldName], rules: `${this.isChecked(this.thirdPartyPaymentOptionId, this.accountParameters.payment_options_ids) ? 'required' : ''}` },
      //     name: fieldName,
      //   }
      //   return props
      // }
      return {
        is: this.accountParameters[fieldName].type,
        field: this.accountParameters[fieldName],
        name: fieldName,
      }
    },
    isDBNNameValid(response) {
      // if you return false it will be valid if you return true it will display error
      return response.cust_name_dba === this.defCustomer.cust_name_dba
    },
    stayInThisPage() {
      this.$refs.uniqueModal.hide()
    },
    checkCustomerName(customer) {
      if (!this.defCustomer.id && customer.length !== 0 && typeof customer === 'object') {
        this.existCustomer = customer
        this.$refs.uniqueModal.show()
      }
    },
    clearExistingCustomer() {
      this.existCustomers = []
    },
    redicrectToTheExistingCustomerUpdate(id) {
      this.$router.push({ name: 'home-customers-general-info', params: { id } })
    },
    getCheckboxValue(e, id, listName) {
      if (!this.accountParametersForm[listName]) {
        this.$set(this.accountParametersForm, listName, [])
      }
      if (e) {
        if (!this.accountParametersForm[listName].includes(id)) {
          this.accountParametersForm[listName].push(id)
        }
      } else {
        this.accountParametersForm[listName] = this.accountParametersForm[listName].filter(item => item !== id)
      }
    },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const CUSTOMER_DEFAULT = 'setting-customer'
    if (!store.hasModule(CUSTOMER_DEFAULT)) store.registerModule(CUSTOMER_DEFAULT, customerDefault)
    const { accountParameters, suggestionTableColumns } = config()
    const fields = { ...accountParameters }
    return {
      MODULE_NAME,
      CUSTOMER_DEFAULT,
      accountParameters,
      suggestionTableColumns,
      fields,
    }
  },
}
</script>
